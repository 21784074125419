<template>
    <div class="body_bg">
    </div>
</template>

<script>
    export default {
        name: "PageNotFound"
    }
</script>

<style scoped>
    .body_bg {
        background: url("../assets/img/404.png") center no-repeat;
        background-size: 100% 100%;
        height: 100vh;
        width: 100vw;
    }
</style>